import styled from 'styled-components';

const StyledSocialNetworks = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`

const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
`

const Item = styled.li`
  position: relative;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;

  a {
    position: relative;
    width: 100%;
    height: 100%;

    img { rotate: 90deg }
  }
`

export { StyledSocialNetworks, List, Item }