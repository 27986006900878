import styled from 'styled-components';

const Section = styled.section`
  position: relative;
  z-index: 1;
  background-color: var(--background-primary);

  .aberration-container {
    display: block;
    position: absolute;
    z-index: calc(var(--z-header) - 1);
    inset: 0;
    overflow: hidden;
    mix-blend-mode: color-dodge;

    @media screen and (min-width: 1280px) {
      inset: 0 35% 0 35%;
    }

    div {
      margin-inline: auto;
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    line-height: normal;
    margin-bottom: 2rem;
    color: var(--text-primary);
    font-size: var(--text-big);
    -webkit-text-stroke: 0.5px var(--text-primary);
    text-align: center;
    margin-inline: auto;
    width: min(50rem, 100%);

    .line {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    strong { -webkit-text-stroke: 0.5px var(--accent-color); }

    @media screen and (min-width: 1280px) {
      margin: auto 0;
      width: 100%;
    }
  }

  .b-cover__text {
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin-inline: auto;
    width: min(50rem, 100%);

    strong { color: var(--accent-color); }

    @media screen and (min-width: 1280px) {
      width: 100%;

      p { margin: auto 0 4rem 0; }
    }
  }

  @media screen and (min-width: 1280px) {
    h1,
    .b-cover__text {
      text-align: left;
    }
  }
`

export { Section }