import styled from 'styled-components';

const StyledPreFooter = styled.section`
  position: relative;
  z-index: 1;

  .atropos {
    width: 100%;
    height: 100%;
    
    &-inner {
      border-radius: 2rem;
      border: 0.1rem solid var(--text-muted);
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 60rem;
  transform-style: preserve-3d;

  @media screen and (min-width: 768px) { height: 80vh; }
  
  @media screen and (min-width: 1280px) { height: 90vh; }

  @media screen and (min-width: 1536px) { height: 85vh; }
`;

const Container = styled.div`
  display: grid;
  position: relative;
  place-items: center;
  background-color: var(--background-secondary);
  width: 100%;
  height: 100%;
  padding: 5rem 0 3rem 0;

  @media screen and (min-width: 1280px) { padding: 0; }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  place-items: center;
  gap: 2.5rem;

  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.25rem;
    color: var(--text-primary);

    span:nth-child(2) {
      font-size: var(--text-big);
      text-transform: uppercase;
      width: min(55rem, 100%);

      .line {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }
`

const Area = styled.div`
  display: grid;
  place-items: center;
  width: 150px;
  height: 150px;

  .button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    color: var(--text-primary);

    @media screen and (hover: hover) {
      &:hover {
        .button__text { display: none; }

        .button__icon { display: block; }
      }
    }

    &__circle {
      position: absolute;
      inset: 0;
      border-radius: 50%;
      border: 0.5px solid var(--text-primary);
    }

    &__text {
      font-size: 0.6rem;
      text-transform: uppercase;
      letter-spacing: 0.12rem;
      line-height: 1;

      p:nth-child(2) { margin-left: 0.5rem; }
    }

    &__icon {
      display: none;
      font-size: 1.5rem;
    }
  }
`

export { StyledPreFooter, Container, Content, Area, Wrapper }