import { Canvas } from '@react-three/fiber'
import { Model } from './Model'
import React from 'react'

export const Scene = ({ activeMenu, images }) => {
  return (
    <div className='hidden xl:block fixed inset-0'>
      <Canvas>
        <Model images={images} activeMenu={activeMenu} />
      </Canvas>
    </div>
  )
}