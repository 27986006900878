import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { List } from './Styles'
import { Anton, Nunito_Sans } from 'next/font/google'
import Link from 'next/link'
import Image from 'next/image'
import { useGSAP } from '@gsap/react'
import SplitType from 'split-type'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const nunito_sans = Nunito_Sans({
  weight: ['400'],
  subsets: ['latin'],
})

export const Container = ({ projects, setActiveMenu, setImages }) => {
  const listRef = useRef(null)

  useEffect(() => {
    const projectImages = document.querySelectorAll('.projectImage')
  
    const imagesArray = Array.from(projectImages).map(image => ({
      src: image.src,
      title: image.title
    }))
  
    setImages(imagesArray)
  }, [projects, setImages])

  useGSAP(() => {
    if (!listRef.current) return

    const li = listRef.current.querySelectorAll('li')

    li.forEach((item) => {
      const [title, line] = [
        item.querySelector('h3'),
        item.querySelector('.tech__line')
      ]

      gsap.fromTo(item, {
        yPercent: 50, opacity: 0
      }, {
        yPercent: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
          end: 'center 90%',
          scrub: 3
        }
      })

      gsap.fromTo(title, {
        backgroundSize: 0
      }, {
        backgroundSize: '100%',
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
          end: 'center 90%',
          scrub: 1
        }
      })

      gsap.fromTo(line, {
        width: 0
      }, {
        width: '100%',
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
          end: 'center 90%',
          scrub: 3
        }
      })
    })
  })

  useEffect(() => {
    if (!listRef.current) return

    const links = Array.from(listRef.current.querySelectorAll('a'))
    const timelines = []

    links.forEach((link) => {
      new SplitType(link.querySelectorAll('span'), { types: 'chars' })

      const chars = link.querySelectorAll('.show .char')
      const charsHide = link.querySelectorAll('.hide .char')

      gsap.set(charsHide, {
        y: 5,
        rotateX: -90,
        opacity: 0
      })

      const tl = gsap.timeline({ paused: true })

      tl
        .to(chars, {
          y: -5,
          opacity: 0,
          rotateX: 90,
          stagger: 0.02,
          ease: 'sine.out',
        })
        .to(charsHide, {
          y: 0,
          rotateX: 0,
          opacity: 1,
          stagger: 0.02,
          ease: 'sine.out',
        }, '<0.1')

      link.addEventListener('mouseenter', () => tl.play())
      link.addEventListener('mouseleave', () => tl.reverse())

      timelines.push({ link, tl })
    })

    return () => {
      timelines.forEach(({ link, tl }) => {
        link.removeEventListener('mouseenter', () => tl.play())
        link.removeEventListener('mouseleave', () => tl.reverse())
      })
    }
  }, [])

  return (
    <div className='relative mix-blend-difference z-10 text-white h-full w-full grid grid-cols-12 xl:grid-cols-24 gap-x-2.5 xl:gap-16 '>
      <List ref={listRef} onMouseLeave={() => { setActiveMenu(null) }} className='col-start-2 col-end-12 xl:col-start-3 xl:col-end-23'>
        {
          projects.map((project, i) => {
            return (
              <li data-cursor='-hidden' onMouseOver={() => { setActiveMenu(i) }} key={project.title}>
                <div className='tech'>
                  <div className='tech__img'>
                    <Image src={project.image.url} alt={project.image.alt} className='object-cover projectImage' fill />
                  </div>
                  <div className='tech__head'>
                    <div className='tech__head-title'>
                      <h3 className={`${anton.className} upper text-4xl`}>{project.title}</h3>
                      <Link
                        aria-label={project.link.title}
                        target='_blank'
                        rel='noopener noreferrer'
                        href={project.link.url}
                        className={`${nunito_sans.className}`}
                      >
                        <span className='show text-sm'>(découvrir)</span>
                        <span className='hide text-sm'>(découvrir)</span>
                      </Link>
                    </div>
                    {project.collaboration && (
                      <span className={`${nunito_sans.className}`}>
                        Réalisé avec{' '}
                        <Link
                          href={project.collaboration.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='show text-sm'>{project.collaboration.title}</span>
                          <span className='hide text-sm'>{project.collaboration.title}</span>
                        </Link>
                      </span>
                    )}
                  </div>
                  <div className='tech__content'>
                    <span className={`${anton.className}`}>Technologies :</span>
                    <p className={`${nunito_sans.className}`}>{project.stack}</p>
                  </div>
                  <div className='tech__line'></div>
                </div>
              </li>
            )
          })
        }
      </List>
    </div>
  )
}