import styled from 'styled-components'

const Section = styled.section`
  position: relative;
  overflow: hidden;
  padding: 6.25rem 0rem;
  background: var(--background-primary);

  @media screen and (min-width: 1280px) { padding: 10vh 0rem; }
`

const List = styled.ul`
  margin-top: 1.25rem;

  @media screen and (min-width: 1280px) {
    li { overflow: hidden; }
  }

  .tech {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 1.875rem 0;

    @media screen and (min-width: 1024px) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media screen and (min-width: 1280px) {
      padding: 5vh 0;
    }

    &__img {
      display: block;
      border-radius: 0.5rem;
      margin-bottom: 1.875rem;
      overflow: hidden;
      position: relative;
      border: 0.1rem solid var(--text-muted);
      height: 17.5rem;

      @media screen and (min-width: 768px) {
        width: 100%;
        height: 35rem;
      }

      @media screen and (min-width: 1024px) { height: 46rem; }

      @media screen and (min-width: 1280px) { display: none; }
    }

    &__head {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &-title {
        display: inline-flex;
        gap: 0.5rem;
        align-items: baseline;

        h3 {
          color: rgba(255, 255, 255, 0.2);
          background: linear-gradient(to right, var(--text-primary), var(--text-primary))
            no-repeat;
          -webkit-background-clip: text;
          background-clip: text;
          background-size: 0%;
          transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.5s;

          @media screen and (hover: hover) {
            position: relative;
            z-index: 3;
          }
        }

        a {
          white-space: nowrap;
          font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1440 - 320)));
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.25rem;
      
      @media screen and (min-width: 1024px) {
        margin-top: 0;
        width: 20rem;
      }

      span {
        color: var(--text-primary);
        font-size: var(--font__title);
        letter-spacing: 0.075rem;
        text-transform: uppercase;
      }

      p {
        color: var(--text-muted);
        font-size: 0.75rem;
        letter-spacing: 0.075rem;

        @media screen and (hover: hover) {
          position: relative;
          z-index: 3;
          color: var(--text-muted);
          transition: color 0.5s ease-out;
        }
      }
    }

    a {
      position: relative;
      color: var(--accent-color);

      span {
        &.hide {
          position: absolute;
          inset: 0;
        }

        &.word {
          display: inline-flex;
          align-items: center;
        }
      }
    }

    &__line {
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 0.0625rem;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`

export { Section, List }