import styled from 'styled-components';

const Section = styled.section`
  position: relative;
  z-index: 1;
  padding: 6.25rem 0rem;
  background: var(--background-primary);

  @media screen and (min-width: 1280px) { padding: 10vh 0rem; }
`

const List = styled.ul`
  margin-top: 1.25rem;

  li {
    display: grid;
    pointer-events: none;
    overflow: hidden;

    @media screen and (hover: hover) {
      position: relative;
      pointer-events: all;

      div { pointer-events: none; }

      &:hover {
        .background { height: 100%; }

        h3 {
          color: var(--background-primary);
          transform: translateX(2rem);
        }

        p .line .word { transform: translate3d(0, 0, 0); }
      }
    }

    h3 {
      margin: 1.5rem 0;
      color: rgba(255, 255, 255, 0.2);
      background: linear-gradient(to right, var(--text-primary), var(--text-primary)) no-repeat;
      -webkit-background-clip: text;
      background-clip: text;
      background-size: 0%;
      transition: all 0.75s var(--ease-cubic);

      @media screen and (min-width: 768px) { margin: 0; }
    }

    p {
      margin-bottom: 1.5rem;

      @media screen and (min-width: 640px) { width: min(18.25rem, 100%); }

      @media screen and (min-width: 768px) { margin-bottom: 0; }

      @media screen and (hover: hover) {
        color: var(--background-primary);

        .line {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

          .word {
            will-change: transform;
            transform: translate3d(0, 110%, 0);
            transition: all 0.75s var(--ease-cubic);
          }
        }

      }
    }

    span.line {
      display: inline;
      height: 0.0625rem;
      align-self: stretch;
    }

    .background {
      display: none;

      @media screen and (hover: hover) {
        display: block;
        position: absolute;
        inset: 0;
        z-index: -1;
        background-color: var(--accent-color);
        height: 0;
        transition: height 0.75s var(--ease-cubic);
      }
    }
  }
`

export { Section, List }