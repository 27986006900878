import styled from 'styled-components';

const Area = styled.div`
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
`

const Button = styled.button`
  position: relative;
  border-radius: 50%;
  border: 0.1rem solid var(--text-muted);
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  cursor: pointer;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--text-primary);
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out;
  }

  & > div:nth-child(1) { transform: translate(-50%, 200%); }

  @media screen and (hover: hover) {
    &:hover {
      & > div:nth-child(1) { transform: translate(-50%, -50%); }
      & > div:nth-child(2) { transform: translate(-50%, -250%); }
    }
  }
`

export { Area, Button }