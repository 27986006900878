import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { useLenis } from 'lenis/react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { Area, Button } from './Styles'
import { GsapMagnetic } from '../GsapMagnetic'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP)

export const ScrollTopButton = () => {
  const lenis = useLenis(ScrollTrigger.update)
  const container = useRef(null)
  const button = useRef(null)

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: 'footer',
        start: 'top 80%',
        end: 'bottom bottom',
        scrub: true
      }
    })

    tl
      .fromTo(container.current, {
        opacity: 0,
        scale: 0,
        pointerEvents: 'none'
      },
        {
          opacity: 1,
          scale: 1,
          pointerEvents: 'all'
        })
  })

  useEffect(() => {
    if (lenis) button.current.addEventListener('click', () => lenis.scrollTo(0))
  }, [lenis])

  return (
    <div ref={container} data-cursor='-hidden'>
      <GsapMagnetic>
        <Area>
          <Button ref={button} aria-label="Scroll on top">
            <div>
              <AiOutlineArrowUp />
            </div>
            <div>
              <AiOutlineArrowUp />
            </div>
          </Button>
        </Area>
      </GsapMagnetic>
    </div>
  )
}