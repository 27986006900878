import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { Header } from './Styles'
import { Maitree } from 'next/font/google'
import { Logo } from './Logo'
import { useScrollToAnchor } from 'hooks/useScrollToAnchor'
import SplitType from 'split-type'

const maitree = Maitree({
  weight: ['500'],
  subsets: ['latin'],
})

export const Menu = ({ isFirstLoaded }) => {
  const nav = useRef(null)
  const logoRef = useRef(null)
  const scrollToAnchor = useScrollToAnchor()

  useEffect(() => {
    if (!nav.current || !logoRef.current) return

    if (!isFirstLoaded) {
      gsap.set([nav.current, logoRef.current], {
        yPercent: -100,
        opacity: 0,
      })
    } else {
      gsap.to([nav.current, logoRef.current], {
        yPercent: 0,
        opacity: 1,
        duration: 1,
        ease: 'sine.out',
      })
    }

  }, [isFirstLoaded])

  useEffect(() => {
    if (!nav.current) return

    const links = Array.from(nav.current.querySelectorAll('button'))
    const timelines = []

    links.forEach((link) => {
      new SplitType(link.querySelectorAll('span'), { types: 'chars' })

      const chars = link.querySelectorAll('.show .char')
      const charsHide = link.querySelectorAll('.hide .char')

      gsap.set(charsHide, {
        y: 5,
        rotateX: -90,
        opacity: 0
      })

      const tl = gsap.timeline({ paused: true })

      tl
        .to(chars, {
          y: -5,
          opacity: 0,
          rotateX: 90,
          stagger: 0.02,
          ease: 'sine.out',
        })
        .to(charsHide, {
          y: 0,
          rotateX: 0,
          opacity: 1,
          stagger: 0.02,
          ease: 'sine.out',
        }, '<0.1')

      link.addEventListener('mouseenter', () => tl.play())
      link.addEventListener('mouseleave', () => tl.reverse())

      timelines.push({ link, tl })
    })

    return () => {
      timelines.forEach(({ link, tl }) => {
        link.removeEventListener('mouseenter', () => tl.play())
        link.removeEventListener('mouseleave', () => tl.reverse())
      })
    }
  }, [])

  return (
    <Header>
      <div className='grid grid-cols-12 xl:grid-cols-24 justify-between items-center py-2 gap-x-2.5'>
        <div ref={logoRef} className='logo col-start-2 col-end-8 xl:col-start-3 xl:col-end-7'>
          <Logo />
        </div>
        <nav
          ref={nav}
          className={`${maitree.className} col-end-12 col-span-3 text-right xl:col-end-23 xl:col-span-2`}
        >
          <ul>
            {['À propos', 'Projets', 'Contact'].map((label) => (
              <li key={label} data-cursor='-hidden'>
                <button onClick={scrollToAnchor(label === 'À propos' ? 'a-propos' : label.toLowerCase())}>
                  <span className='show text-sm'>{label}</span>
                  <span className='hide text-sm'>{label}</span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Header>
  )
}