import React, { useRef } from 'react'
import Link from 'next/link'
import { gsap } from 'gsap'
import Image from 'next/image'
import { StyledSocialNetworks, List, Item } from './Styles'
import { GsapMagnetic } from '../GsapMagnetic'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP)

export const SocialNetworks = ({ socials }) => {
  const listRef = useRef(null)

  return (
    <StyledSocialNetworks>
      <List ref={listRef} className='social-networks'>
        {socials.map((item, i) => {
          return (
            <GsapMagnetic key={i}>
              <Item data-cursor-stick={`#stick-${i}`} id={`stick-${i}`} data-cursor='-sm -exclusion'>
                <Link
                  className='social-networks__item'
                  href={item.link.url}
                  target='_blank'
                  aria-label={item.link.title}
                >
                  <Image
                    src={item.image.sourceUrl}
                    fill
                    className='object-contain'
                    sizes={item.image.sizes}
                    alt={item.image.altText}
                  />
                </Link>
              </Item>
            </GsapMagnetic>
          )
        })}
      </List>
    </StyledSocialNetworks>
  )
}