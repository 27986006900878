export const Logo = () => {
  return (
    <>
      <svg data-cursor='-exclusion -l' width="145" height="28" viewBox="0 0 145 28" fill="none">
        <path d="M0.46875 28L3.125 0.5H12.4531L15.0625 28H9.85938L9.46875 23.5625H6.15625L5.8125 28H0.46875ZM6.54688 19.1719H9.04688L7.84375 5.1875H7.59375L6.54688 19.1719ZM16.75 28V0.5H25.1875C26.5938 0.5 27.6562 0.822917 28.375 1.46875C29.0938 2.10417 29.5729 3 29.8125 4.15625C30.0625 5.30208 30.1875 6.65104 30.1875 8.20312C30.1875 9.70312 29.9948 10.901 29.6094 11.7969C29.2344 12.6927 28.5208 13.3125 27.4688 13.6562C28.3333 13.8333 28.9375 14.2656 29.2812 14.9531C29.6354 15.6302 29.8125 16.5104 29.8125 17.5938V28H24.3906V17.2344C24.3906 16.4323 24.224 15.9375 23.8906 15.75C23.5677 15.5521 23.0417 15.4531 22.3125 15.4531V28H16.75ZM22.3438 10.6875H23.6719C24.4323 10.6875 24.8125 9.85938 24.8125 8.20312C24.8125 7.13021 24.7292 6.42708 24.5625 6.09375C24.3958 5.76042 24.0833 5.59375 23.625 5.59375H22.3438V10.6875ZM34.3594 28V5.78125H31.0938V0.5H43.125V5.78125H39.8594V28H34.3594ZM54.6875 28L51.2969 0.484375H56.5312L58.5469 19.2031L60.3438 0.484375H65.5781L62.1875 28H54.6875ZM65.7812 28L68.4375 0.5H77.7656L80.375 28H75.1719L74.7812 23.5625H71.4688L71.125 28H65.7812ZM71.8594 19.1719H74.3594L73.1562 5.1875H72.9062L71.8594 19.1719ZM82.0625 28V0.5H87.7188L90.25 13.6719V0.5H95.5625V28H90.1875L87.4375 14.25V28H82.0625ZM98 28V0.5H103.656L106.188 13.6719V0.5H111.5V28H106.125L103.375 14.25V28H98ZM113.188 28L115.844 0.5H125.172L127.781 28H122.578L122.188 23.5625H118.875L118.531 28H113.188ZM119.266 19.1719H121.766L120.562 5.1875H120.312L119.266 19.1719ZM129.469 28V0.5H134.938V10.3281H137.531V0.5H143V28H137.531V15.5312H134.938V28H129.469Z" fill="#FFFFFE" />
        <path d="M45 18V17C45 16.4477 45.4477 16 46 16V16C46.5523 16 47 16.4477 47 17V18H45Z" fill="#FF6300" />
        <path d="M45 16V14V14C46.1046 14 47 14.8954 47 16V16H45Z" fill="#FF6300" />
        <path d="M49 18C47.8954 18 47 17.1046 47 16V16H49V18V18Z" fill="#FF6300" />
        <path d="M48 16C47.4477 16 47 15.5523 47 15V14H49V15C49 15.5523 48.5523 16 48 16V16Z" fill="#FF6300" />
      </svg>
    </>
  )
}