import React, { useRef } from 'react'
import { Anton, Nunito_Sans } from 'next/font/google'
import { SmallTitle } from 'components/SmallTitle'
import { gsap } from 'gsap'
import { Section, List } from './Styles'
import SplitType from 'split-type'
import { useGSAP } from '@gsap/react'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const nunitoSans = Nunito_Sans({
  weight: ['600'],
  subsets: ['latin'],
})

const ListItem = ({ item, isFirst, anton, nunitoSans }) => (
  <li data-cursor="-text -exclusion">
    <div className="background"></div>
    {isFirst && <span className="line bg-background-secondary"></span>}
    <div className="grid grid-cols-12 xl:grid-cols-24 gap-x-2.5">
      <div className="col-start-2 col-end-12 xl:col-start-5 xl:col-end-21 flex flex-col sm:flex-row sm:items-center justify-between gap-x-2.5">
        <h3 className={`${anton.className} text-5xl upper`}>{item.title}</h3>
        <p className={`${nunitoSans.className} text-sm`}>{item.text}</p>
      </div>
    </div>
    <span className="line bg-background-secondary"></span>
  </li>
)

export const TitleItems = ({ title, items }) => {
  const listRef = useRef(null)

  useGSAP(() => {
    if (!listRef.current) return

    const lines = listRef.current.querySelectorAll('span.line')
    const listItems = listRef.current.querySelectorAll('li')

    gsap.set(lines, { width: 0 })

    lines.forEach((line) => {
      gsap.to(line, {
        scrollTrigger: {
          trigger: line,
          start: 'top bottom',
          end: 'center 90%',
          scrub: 3
        },
        width: '100%',
      })
    })

    listItems.forEach((item) => {
      const title = item.querySelector('h3')
      const text = item.querySelector('p')

      new SplitType(text, { types: 'lines, words' })

      gsap.to(title, {
        backgroundSize: '100%',
        scrollTrigger: {
          trigger: title,
          start: 'top bottom',
          end: 'center 90%',
          scrub: 1
        }
      })
    })
  })

  return (
    <Section id='a-propos'>
      <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='col-start-2 col-end-5 xl:col-start-5 xl:col-end-10'>
          <SmallTitle title={title} level={'2'} />
        </div>
      </div>
      <List ref={listRef}>
        {items?.map((item, i) => (
          <ListItem
            key={i}
            item={item}
            isFirst={i === 0}
            anton={anton}
            nunitoSans={nunitoSans}
          />
        ))}
      </List>
    </Section>
  )
}