import styled from 'styled-components';

const Section = styled.section`
  position: relative;
  z-index: 1;
  background-color: var(--background-primary);
  overflow: hidden;

  .aberration-container {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
      position: absolute;
      z-index: calc(var(--z-networks) + 1);
      inset: 0;
      overflow: hidden;
      mix-blend-mode: lighten;
    }
  }

  .content { margin: 6rem 0; }
`

export { Section }