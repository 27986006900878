// import { Cover } from "components/Cover";
import { Heading } from "components/Heading";
import { Paragraph } from 'components/Paragraph';
import { theme } from 'theme';
import { Columns } from 'components/Columns';
import { Column } from 'components/Column';
import { PostTitle } from 'components/PostTitle';
import Image from 'next/image';
import { Gallery } from 'components/Gallery';

import { Cover } from 'components/blocks/Cover';
import { TitleItems } from 'components/blocks/TitleItems';
import { TitleContentImage } from 'components/blocks/TitleContentImage';
import { PreFooter } from 'components/blocks/PreFooter';
import { Projects } from 'components/blocks/Projects';

export const BlockRenderer = ({ blocks, isFirstLoaded }) => {
  return blocks.map(block => {
    switch (block.name) {
      // case "core/gallery": {
      //   return (
      //     <Gallery
      //       key={block.id}
      //       columns={block.attributes.columns || 3}
      //       cropImages={block.attributes.imageCrop}
      //       items={block.innerBlocks}
      //     />
      //   )
      // }
      // case "core/paragraph": {
      //   return (
      //     <Paragraph
      //       key={block.id}
      //       content={block.attributes.content}
      //       textAlign={block.attributes.align}
      //       textColor={theme[block.attributes.textColor] || block.attributes.style?.color?.text}
      //     />
      //   )
      // }
      // case "core/heading": {
      //   return (
      //     <Heading
      //       key={block.id}
      //       level={block.attributes.level}
      //       content={block.attributes.content}
      //       textAlign={block.attributes.textAlign}
      //     />
      //   )
      // }
      // case "core/post-title": {
      //   return (
      //     <PostTitle
      //       key={block.id}
      //       level={block.attributes.level}
      //       textAlign={block.attributes.textAlign}
      //     />
      //   )
      // }
      // case "core/cover": {
      //   return (
      //     <Cover key={block.id} background={block.attributes.url}>
      //       <BlockRenderer blocks={block.innerBlocks} />
      //     </Cover>
      //   )
      // }
      // case "core/columns": {
      //   return (
      //     <Columns
      //       key={block.id}
      //       isStackedOnMobile={block.attributes.isStackedOnMobile}
      //       textColor={theme[block.attributes.textColor] || block.attributes.style?.color?.text}
      //       backgroundColor={theme[block.attributes.backgroundColor] || block.attributes.style?.color?.background}
      //     >
      //       <BlockRenderer blocks={block.innerBlocks} />
      //     </Columns>
      //   )
      // }
      // case "core/column": {
      //   return (
      //     <Column
      //       key={block.id}
      //       width={block.attributes.width}
      //       textColor={theme[block.attributes.textColor] || block.attributes.style?.color?.text}
      //       backgroundColor={theme[block.attributes.backgroundColor] || block.attributes.style?.color?.background}
      //     >
      //       <BlockRenderer blocks={block.innerBlocks} />
      //     </Column>
      //   )
      // }
      // case "core/block":
      // case "core/group": {
      //   return (
      //     <BlockRenderer key={block.id} blocks={block.innerBlocks} />
      //   )
      // }
      // case "core/image": {
      //   return (
      //     <Image
      //       key={block.id}
      //       src={block.attributes.url}
      //       height={block.attributes.height}
      //       width={block.attributes.width}
      //       alt={block.attributes.alt || ""}
      //     />
      //   );
      // }
      case "acf/b-cover": {
        const { image, content, title } = block.attributes.data

        return (
          <Cover
            key={block.id}
            image={image}
            title={title}
            content={content}
            isFirstLoaded={isFirstLoaded}
          />
        )
      }
      case "acf/b-title-items": {
        const { title, items: itemsCount } = block.attributes.data

        const items = Array.from({ length: itemsCount }, (_, i) => ({
          title: block.attributes.data[`items_${i}_title`],
          text: block.attributes.data[`items_${i}_text`],
        }))

        return (
          <TitleItems
            key={block.id}
            title={title}
            items={items}
          />
        )
      }
      case "acf/b-title-content-image": {
        const { title, content, image } = block.attributes.data

        return (
          <TitleContentImage
            key={block.id}
            title={title}
            content={content}
            image={image}
          />
        )
      }
      case "acf/b-pre-footer": {
        const { title, first_text, second_text, suptitle, link } = block.attributes.data

        return (
          <PreFooter
            key={block.id}
            title={title}
            firstText={first_text}
            secondText={second_text}
            suptitle={suptitle}
            link={link}
          />
        )
      }
      case "acf/b-projects": {
        const { title, projects: projectsCount } = block.attributes.data

        const projects = Array.from({ length: projectsCount }, (_, i) => ({
          title: block.attributes.data[`projects_${i}_title`],
          link: block.attributes.data[`projects_${i}_link`],
          stack: block.attributes.data[`projects_${i}_stack`],
          collaboration: block.attributes.data[`projects_${i}_collaboration`],
          image: block.attributes.data[`projects_${i}_image`],
        }))

        return (
          <Projects
            key={block.id}
            title={title}
            projects={projects}
          />
        )
      }
      default: {
        // console.log('UNKNOWN: ', block);
        return null;
      }
    }
  })
}