import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Overlay, EntryText, StyledPreloader } from './Styles'
import { Anton } from 'next/font/google'
import { gsap } from 'gsap'
import { useLenis } from 'lenis/react'
import { InnerLogo } from './InnerLogo'
import { Counter } from './Counter'
import { ImageAberration } from 'components/ImageAberration'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

export const Preloader = ({ onComplete, image }) => {
  const [counterComplete, setCounterComplete] = useState(false)
  const preloaderRef = useRef(null)
  const overlayRef = useRef(null)
  const entryTextRef = useRef(null)
  const lenis = useLenis()

  const handleComplete = useCallback(() => {
    if (onComplete) onComplete()
  }, [onComplete])

  useEffect(() => {
    if (!counterComplete) return

    lenis?.stop()

    const spans = entryTextRef.current?.querySelectorAll('span')

    if (!spans || spans.length < 2) return

    const tl = gsap.timeline({
      onComplete: () => {
        lenis?.start()

        gsap.to(preloaderRef.current, {
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out',
          onComplete: handleComplete,
        })
      },
    })

    tl
      .to(spans, {
        transform: 'translate(0, 0)',
        duration: 1,
        ease: 'power2.out'
      })
      .to(spans[0], {
        y: -20,
        ease: 'sine.inOut'
      })
      .to(spans[1], {
        y: 20,
        ease: 'sine.inOut'
      }, '<')
      .to(overlayRef.current, {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        duration: 1,
        ease: 'sine.out'
      })
      .to([spans[0], spans[1]], {
        x: (i) => (i === 0 ? '-70vw' : '70vw'),
        duration: 1,
        ease: 'power2.inOut',
      }, '<')

  }, [counterComplete, lenis, handleComplete])

  return (
    <StyledPreloader ref={preloaderRef}>
      <Overlay ref={overlayRef} className="overlay">
        <div data-cursor='-exclusion -xxl' className='overlay__img'>
          <ImageAberration imgSrc={image.sourceUrl} imgAlt={image.altText} id={image.id} contain />
        </div>
      </Overlay>
      <Counter onCounterComplete={() => setCounterComplete(true)} />
      <EntryText ref={entryTextRef} className={`${anton.className} clip`}>
        <span>art <InnerLogo />v</span>
        <span>annah</span>
      </EntryText>
    </StyledPreloader>
  )
}
