import styled from 'styled-components';

const StyledPreloader = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: var(--text-primary);
  z-index: calc(var(--z-header) - 1);
`;

const StyledCounter = styled.div`
  position: fixed;
	inset: auto 1rem 1rem auto;
	display: flex;
	height: 7rem;
	font-size: 7rem;
  color: var(--background-primary);
	line-height: normal;
	clip-path: polygon(0 0, 100% 0, 100% 7rem, 0 7rem);
	font-weight: 400;

  .counter--first,
  .counter--second,
  .counter--third {
    position: relative;
	  top: -1.2rem;
  }

  .counter__num--first {
    position: relative;
	  right: -1.2rem;
  }

  .counter__num--second {
    position: relative;
	  right: -1rem;
  }
`

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 25%, 50% 35%, 50% 75%, 50% 65%);
  
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    background-color: var(--background-primary);
  }

  .overlay__img {
    display: block;
    position: absolute;
    z-index: calc(var(--z-header) - 1);
    inset: 0;
    overflow: hidden;
    mix-blend-mode: color-dodge;

    @media screen and (min-width: 1280px) {
      inset: 0 35% 0 35%;
    }

    div {
      margin-inline: auto;
      width: 100%;
      height: 100%;
    }
  }
`

const EntryText = styled.div`
  position: absolute;
  mix-blend-mode: difference;
  display: flex;
  width: 100%;
  justify-content: center;
  color: var(--text-primary);
  font-size: 6rem;
  z-index: 10;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 12rem;
  }

  span {
    display: inline-flex;
    align-items: center;
    transform: translate(0%, 100%)
  }
`

export { StyledCounter, Overlay, EntryText, StyledPreloader }