import React, { useEffect, useRef } from 'react'
import { Nunito_Sans } from 'next/font/google'
import { SmallTitle } from 'components/SmallTitle'
import { ImageAberration } from 'components/ImageAberration'
import { gsap } from 'gsap'
import { Section } from './Styles'
import parse from 'html-react-parser'

const nunitoSans = Nunito_Sans({
  weight: ['300'],
  subsets: ['latin'],
})

export const TitleContentImage = ({ title, content, image }) => {
  const richTextRef = useRef(null)

  useEffect(() => {
    if (!richTextRef.current) return

    const paragraphs = richTextRef.current.querySelectorAll('p')

    paragraphs.forEach((p) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: p,
          start: 'top bottom',
          end: 'top 85%',
          scrub: true,
        },
      })

      tl.fromTo(p, {
        opacity: 0,
      },
        { opacity: 1 })
    })
  })

  return (
    <Section>
      <div className='grid grid-cols-12 lg:grid-cols-24 gap-x-2.5 items-center'>
        <div className='col-start-2 col-end-12 lg:col-start-3 lg:col-end-11 xl:col-end-12 h-full xl:h-[66rem] lg:relative'>
          <div data-cursor='-exclusion -xxl' className='aberration-container'>
            <ImageAberration imgSrc={image.url} imgAlt={image.alt} id={'about'} />
          </div>
        </div>
        <div className='content col-start-2 col-end-12 lg:col-start-12 lg:col-end-23 xl:col-start-14 xl:col-end-23 flex flex-col gap-y-5'>
          <SmallTitle title={title} level={'2'} />
          <div
            ref={richTextRef}
            className={`${nunitoSans.className} text-text-muted text-base richtext`}
          >
            {parse(content)}
          </div>
        </div>
      </div>
    </Section>
  )
}