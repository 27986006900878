import React, { useEffect, useRef } from 'react'
import { Maitree, Nunito_Sans } from 'next/font/google'
import { gsap } from 'gsap'
import Link from 'next/link'
import { SmallTitle } from 'components/SmallTitle'
import { ScrollTopButton } from 'components/ScrollTopButton'
import { StyledFooter } from './Styles'
import SplitType from 'split-type'
import { SocialNetworks } from 'components/SocialNetworks'

const maitree = Maitree({
  weight: ['300'],
  subsets: ['latin'],
})

const nunitoSans = Nunito_Sans({
  weight: ['300'],
  subsets: ['latin'],
})

export const Footer = ({ title, links, mail, socials }) => {
  const contact = useRef(null)

  useEffect(() => {
    if (!contact.current) return

    new SplitType(contact.current.querySelectorAll('span'), { types: 'chars' })

    const showChars = contact.current.querySelectorAll('.show .char')
    const hideChars = contact.current.querySelectorAll('.hide .char')

    gsap.set(hideChars, { y: 5, rotateX: -90, opacity: 0 })

    const tl = gsap.timeline({ paused: true })

    tl
      .to(showChars, {
        y: -5,
        opacity: 0,
        rotateX: 90,
        stagger: 0.02,
        ease: 'sine.out',
      })
      .to(hideChars, {
        y: 0,
        rotateX: 0,
        opacity: 1,
        stagger: 0.02,
        ease: 'sine.out',
      }, '<0.1')

    contact.current.addEventListener('mouseenter', () => tl.play())
    contact.current.addEventListener('mouseleave', () => tl.reverse())
  })

  return (
    <StyledFooter>
      <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='col-start-2 col-end-12 xl:col-start-3 xl:col-end-23 flex flex-col gap-y-10'>
          <div className='flex items-center justify-between'>
            <SmallTitle title={title} level={'2'} />
            <ScrollTopButton />
          </div>
          <div className='flex flex-row flex-wrap justify-between items-end'>
            <div className={`${maitree.className} flex flex-col items-start socials`}>
              {links.map((item, index) => (
                <Link
                  data-cursor='-sm -exclusion'
                  key={index}
                  aria-label={item.link.title}
                  href={item.link.url}
                  target={item.link.target}
                >
                  {item.link.title}
                </Link>
              ))}
            </div>
            <div>
              <SocialNetworks socials={socials} />
              <Link
                data-cursor='-hidden'
                ref={contact}
                className={`${nunitoSans.className} email`}
                aria-label={mail.title}
                href={mail.url}
              >
                <span className='show text-sm'>{mail.title}</span>
                <span className='hide text-sm'>{mail.title}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}