import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import Link from 'next/link'
import { FaLocationArrow } from 'react-icons/fa'
import { Anton, Maitree, Nunito_Sans } from 'next/font/google'
import { Atropos } from 'atropos/react'
import { StyledPreFooter, Container, Content, Area, Wrapper } from './Styles'
import { useGSAP } from '@gsap/react'
import SplitType from 'split-type'

gsap.registerPlugin(useGSAP)

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const maitree = Maitree({
  weight: ['300'],
  subsets: ['latin'],
})

const nunitoSans = Nunito_Sans({
  weight: ['300'],
  subsets: ['latin'],
})

export const PreFooter = ({ title, firstText, secondText, suptitle, link }) => {
  const sectionRef = useRef(null)
  const wrapperRef = useRef(null)
  const titleFirstRef = useRef(null)
  const titleSecondRef = useRef(null)
  const area = useRef(null)
  const button = useRef(null)
  const icon = useRef(null)
  const circle = useRef(null)
  const circle1 = useRef(null)
  const circle2 = useRef(null)

  const parallaxIt = (e, target, movement) => {
    const boundingRect = area.current.getBoundingClientRect()
    const relX = e.pageX - boundingRect.left
    const relY = e.pageY - boundingRect.top
    const scrollTop = window.scrollY || document.documentElement.scrollTop

    gsap.to(target.current, {
      x: (relX - boundingRect.width / 2) * movement,
      y: (relY - boundingRect.height / 2 - scrollTop) * movement,
      ease: 'power4.out',
      duration: 0.5,
    })
  }

  const callParallax = (e) => {
    if (button.current) parallaxIt(e, button, 0.5)
    if (icon.current) parallaxIt(e, icon, 0.05)
    if (circle.current) parallaxIt(e, circle, 0.01)
    if (circle1.current) parallaxIt(e, circle1, 0.1)
    if (circle2.current) parallaxIt(e, circle2, 0.2)
  }

  const parallaxRefs = [button, icon, circle, circle1, circle2]

  const resetParallax = () => {
    parallaxRefs.forEach((ref) => {
      if (ref.current) resetParallaxTween(ref)
    })
  }

  const resetParallaxTween = (ref) => {
    gsap.to(ref.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: 'back',
    })
  }

  useEffect(() => {
    area.current.addEventListener('mousemove', callParallax)
    area.current.addEventListener('mouseleave', resetParallax)
  })

  useGSAP(() => {
    if (!wrapperRef.current || !sectionRef.current) return;

    const title = new SplitType(titleSecondRef.current, { types: 'lines, words, chars' })

    gsap.set(wrapperRef.current, {
      opacity: 0,
      yPercent: 10,
      pointerEvents: 'none'
    })

    gsap.set(titleFirstRef.current, {
      opacity: 0,
      y: 20
    })

    gsap.set(title.chars, {
      rotateX: -90,
      yPercent: 100,
      opacity: 0,
    })

    gsap.set(area.current, {
      opacity: 0,
      scale: 0,
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top center',
        end: 'top top',
      }
    })

    tl
      .to(wrapperRef.current, {
        opacity: 1,
        yPercent: 0,
        pointerEvents: 'auto'
      })
      .to(titleFirstRef.current, {
        opacity: 1,
        y: 0
      }, '<0.3')
      .to(title.chars, {
        rotateX: 0,
        yPercent: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.02,
        ease: 'power2.out'
      }, '>')
      .to(area.current, {
        opacity: 1,
        scale: 1
      }, '<0.3')
  })

  return (
    <StyledPreFooter ref={sectionRef} id='contact'>
      <div className='min-h-screen grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='min-h-full col-start-2 col-end-12 xl:col-start-3 xl:col-end-23 flex items-center'>
          <Wrapper ref={wrapperRef}>
            <Atropos
              className='atropos'
              activeOffset={20}
              shadow={false}
              rotateTouch={false}
              rotateXMax={5}
              rotateYMax={5}
            >
              <Container>
                <Content className='content'>
                  <h2 data-cursor='-exclusion -xxl'>
                    <span ref={titleFirstRef} className={`${maitree.className}`} >{suptitle}</span>
                    <span ref={titleSecondRef} data-atropos-offset='5' className={`${anton.className}`} >{title}</span>
                  </h2>
                  <Area data-cursor='-hidden' ref={area}>
                    <Link
                      ref={button}
                      className='button'
                      href={link.url}
                      aria-label={link.title}
                    >
                      <div className='button__circle' ref={circle}></div>
                      <div className='button__circle' ref={circle1}></div>
                      <div className='button__circle' ref={circle2}></div>
                      <div className={`${nunitoSans.className} button__text`}>
                        <p>{firstText}</p>
                        <p>{secondText}</p>
                      </div>
                      <div className='button__icon' ref={icon}>
                        <FaLocationArrow />
                      </div>
                    </Link>
                  </Area>
                </Content>
              </Container>
            </Atropos>
          </Wrapper>
        </div>
      </div>
    </StyledPreFooter>
  )
}