import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { StyledCounter } from './Styles'

export const Counter = ({ onCounterComplete }) => {
  const counterFirstRef = useRef(null)
  const counterSecondRef = useRef(null)
  const counterThirdRef = useRef(null)
  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    const digits = [...Array(10).keys()]

    setNumbers([...digits, ...digits, 0])
  }, [])

  useEffect(() => {
    if (!counterThirdRef.current || !counterThirdRef.current.children.length) return

    const animate = (counter, duration, delay = 0) => {
      if (!counter || counter.children.length === 0) return

      const numHeight = counter.children[0]?.clientHeight || 0
      const totalDistance = (counter.children.length - 1) * numHeight

      gsap.to(counter, {
        y: -totalDistance,
        duration,
        delay,
        ease: 'power2.inOut'
      })
    }

    animate(counterThirdRef.current, 5)
    animate(counterSecondRef.current, 6)
    animate(counterFirstRef.current, 2, 4)

    gsap.to([counterFirstRef.current, counterSecondRef.current, counterThirdRef.current], {
      top: '-15rem',
      stagger: { amount: 0.25 },
      delay: 6,
      duration: 1,
      ease: 'power4.inOut',
      onComplete: () => onCounterComplete && onCounterComplete()
    })
  }, [numbers, onCounterComplete]) 

  return (
    <StyledCounter>
      <div ref={counterFirstRef} className='counter--first digit'>
        <div className='counter__num'>0</div>
        <div className='counter__num counter__num--first'>1</div>
      </div>
      <div ref={counterSecondRef} className='counter--second digit'>
        {numbers.map((num, index) => (
          <div 
            key={index} 
            className={`counter__num ${index === 1 ? 'counter__num--second' : ''}`}
          >
            {num}
          </div>
        ))}
      </div>
      <div ref={counterThirdRef} className='counter--third digit'>
        {numbers.map((num, index) => (
          <div key={index} className='counter__num'>{num}</div>
        ))}
      </div>
    </StyledCounter>
  )
}
