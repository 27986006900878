import React, { useEffect, useRef } from 'react'
import { Anton } from 'next/font/google'
import { gsap } from 'gsap'
import SplitType from 'split-type'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

export const SmallTitle = ({ title, level }) => {
  const charsTitleRefs = useRef(null)

  useEffect(() => {
    if (!charsTitleRefs.current) return

    new SplitType(charsTitleRefs.current, {
      types: 'chars'
    })

    const chars = charsTitleRefs.current.querySelectorAll('.char')

    gsap.fromTo(chars, {
      y: 5,
      rotateX: 90,
      opacity: 0
    },
      {
        y: 0,
        rotateX: 0,
        opacity: 1,
        stagger: 0.02,
        ease: 'sine.out',
        scrollTrigger: {
          trigger: charsTitleRefs.current,
          start: 'top bottom',
          end: 'top 85%',
          scrub: 3,
        },
      })
  }, [])

  const HeadingTag = React.createElement(
    `h${level}`,
    {
      dangerouslySetInnerHTML: { __html: title },
      className: `${anton.className} text-sm upper text-text-primary clip`,
      ref: charsTitleRefs
    }
  )

  return HeadingTag
}