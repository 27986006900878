import { useRef, useEffect } from 'react'
import MouseFollower from 'mouse-follower'
import { gsap } from 'gsap'

MouseFollower.registerGSAP(gsap)

export const Cursor = () => {
  const cursorRef = useRef(null)

  useEffect(() => {
    if (!cursorRef.current) {
      cursorRef.current = new MouseFollower({
        speed: 0.55,
        skewing: 2
      })
    }

    return () => {
      if (cursorRef.current) {
        cursorRef.current.destroy()
        cursorRef.current = null
      }
    }
  }, [])
}