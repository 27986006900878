export const vertex = `
  varying vec2 vUv;
  uniform vec2 uDelta;
  uniform float uAmplitude;
  float PI = 3.141592653589793238;

  void main() {
    vUv = uv;
    vec3 newPosition = position;
    newPosition.x += sin(uv.y * PI) * uDelta.x * uAmplitude;
    newPosition.y += sin(uv.x * PI) * uDelta.y * uAmplitude;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
  }
`

export const fragment = `
  varying vec2 vUv;
  uniform sampler2D uTexture;
  uniform float uAlpha;
  void main() {
      vec3 texture = texture2D(uTexture, vUv).rgb;
      gl_FragColor = vec4(texture, uAlpha);
      // gl_FragColor = vec4(1., 0., 0., 1.);
  }
`