import React, { useRef, useEffect } from 'react'
import { Anton, Maitree } from 'next/font/google'
import { ImageAberration } from 'components/ImageAberration'
import { gsap } from 'gsap'
import parse from 'html-react-parser'
import { Section } from './Styles'
import SplitType from 'split-type'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const maitree = Maitree({
  weight: ['200'],
  subsets: ['latin'],
})

export const Cover = ({ image, title, content, isFirstLoaded }) => {
  const aberrationRef = useRef(null)
  const titleRef = useRef(null)
  const richTextRef = useRef(null)

  useEffect(() => {
    const title = new SplitType(titleRef.current, { types: 'lines, words, chars' })
    const text = new SplitType(richTextRef.current.querySelector('p'), { types: 'words, chars' })
    const tl = gsap.timeline()

    if (!isFirstLoaded) {
      if (title) gsap.set(title.words, { opacity: 0 })
      if (text) gsap.set(text.chars, { opacity: 0 })
    } else {
      if (title) {
        tl.fromTo(title.chars, {
          rotateX: -90,
          yPercent: 100,
          opacity: 0,
        }, {
          rotateX: 0,
          yPercent: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.02,
          ease: 'power2.out'
        })
      }
      if (text.chars) {
        tl.fromTo(text.chars, {
          opacity: 0,

        }, {
          opacity: 1,
          stagger: 0.02,
          duration: 1,
          ease: 'power2.out'
        }, '<')
      }
    }
  }, [isFirstLoaded])

  return (
    <Section>
      <div data-cursor='-exclusion -xxl' className='aberration-container' ref={aberrationRef}>
        <ImageAberration imgSrc={image.url} imgAlt={image.alt} id={image.id} contain />
      </div>
      <div className='min-h-screen grid grid-cols-12 xl:grid-cols-24 gap-x-2.5 items-center'>
        <div className='col-start-2 col-end-12 xl:col-start-3 xl:col-end-23 xl:h-full xl:grid xl:grid-cols-20'>
          <h1
            data-cursor='-exclusion -xxl'
            ref={titleRef}
            className={`${anton.className} xl:col-start-1 xl:col-end-9`}
          >
            {title}
          </h1>
          <div
            ref={richTextRef}
            className={`${maitree.className} b-cover__text text-base xl:col-start-14 xl:col-end-21 xl:flex 2xl:col-start-15`}
          >
            {parse(content)}
          </div>
        </div>
      </div>
    </Section>
  )
}