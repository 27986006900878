export const InnerLogo = () => {
  return (
    <>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15L0 11.25C0 9.17893 1.67893 7.5 3.75 7.5V7.5C5.82107 7.5 7.5 9.17893 7.5 11.25V15H0Z" fill="#FFFFFE"/>
        <path d="M0 7.5L0 0V0C4.14214 0 7.5 3.35786 7.5 7.5V7.5L0 7.5Z" fill="#FFFFFE"/>
        <path d="M15 15C10.8579 15 7.5 11.6421 7.5 7.5V7.5L15 7.5V15V15Z" fill="#FFFFFE"/>
        <path d="M11.25 7.5C9.17893 7.5 7.5 5.82107 7.5 3.75L7.5 0L15 0V3.75C15 5.82107 13.3211 7.5 11.25 7.5V7.5Z" fill="#FFFFFE"/>
      </svg>
    </>
  )
}