import { useState } from 'react'
import { Scene } from './Scene'
import { Container } from './Container'
import { SmallTitle } from 'components/SmallTitle'
import { Section } from './Styles'

export const Projects = ({ title, projects }) => {
  const [activeMenu, setActiveMenu] = useState(null)
  const [images, setImages] = useState([{}])

  return (
    <Section id='projets'>
      <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='col-start-2 col-end-5 xl:col-start-3 xl:col-end-10'>
          <SmallTitle title={title} level={'2'} />
        </div>
      </div>
      <Container projects={projects} setActiveMenu={setActiveMenu} setImages={setImages} />
      <Scene projects={projects} activeMenu={activeMenu} images={images} />
    </Section>
  );
}